@media print {
  .noprint {
    display: none;
  }

  section {
    display: block;
    page-break-before: always;
  }

  pre code {
    page-break-after: always;
  }

  @page {
    size: auto;
    margin: 25mm;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  p a, code, pre {
    word-wrap: break-word;
  }

  pre a[href]:after {
    content: '';
  }

  pre abbr[title]:after {
    content: '';
  }

  pre .ir a:after,
  pre a[href^='javascript:']:after,
  pre a[href^='#']:after {
    content: '';
  }
}
